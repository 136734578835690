<template>
  <div class="register">
    <section class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="p-sm-4">
              <h1 class="h3 text-uppercase b-600 text-primary mb-0 headings">
                REGISTRATION INFO
              </h1>
            </div>
            <div class="row" v-if="isUADesktop">
              <div class="col-sm-8 mx-auto text-center">
                <img
                  src="@/assets/img/register/desktop.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-sm-12 py-3">
                <img
                  src="@/assets/img/register/1.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-12 py-3">
                <img
                  src="@/assets/img/register/2.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-12 py-3">
                <img
                  src="@/assets/img/register/3.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <p class="mb-0 mt-3 text-primary fw-bold fs-5"><i>Note:</i></p>
            <p class="mb-0 text-primary b-600 fs-5">
              Additional Networking Dinner tickets can be purchased at SG$115
              (Nett) per pax
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="terms-and-condition py-4">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="p-sm-4 text-center">
              <h1 class="h3 text-uppercase b-600 text-primary mb-0 headings">
                TERMS AND CONDITIONS
              </h1>
            </div>

            <p class="text-uppercase fw-bold text-primary mb-0">
              REGISTRATION FEE
            </p>
            <p class="mb-0">
              All fees quoted are in Singapore Dollars (SGD) and inclusive of 8%
              GST. Travel and accommodation expenses are excluded from the
              registration fee. Registration fees include:
            </p>
            <ul>
              <li>Access to all conference sessions</li>
              <li>Welcome Reception (by invitation only)</li>
              <li>Networking Dinner</li>
              <li>Daily tea-breaks and lunches</li>
            </ul>

            <p class="text-uppercase fw-bold text-primary mb-0">
              CREDIT CARD PAYMENT
            </p>
            <p>
              Online payment is made to
              <span class="fw-bold">Se7en Friday Pte Ltd</span> through
              <span class="fw-bold">Stripe only</span>.
            </p>
            <p class="text-uppercase fw-bold text-primary mb-0">
              CANCELLATION/WITHDRAWAL POLICY
            </p>
            <ol>
              <li>
                A 60% refund will be made for cancellations received on or
                before 15 Aug 2023.
              </li>
              <li>
                A separate refund administrative fee of S$50 will be imposed for
                each refund.
              </li>
              <li>
                Cancellations are to be made via email to the secretariat before
                the above deadline.
              </li>
              <li>
                There will be no refund for cancellation/withdrawal received
                after 15 Aug 2023.
              </li>
              <li>
                There will be no refund for registrants who has made payment but
                did not attend the conference in person. The organizers will not
                be mailing out conference publications or materials to such
                individuals
              </li>
            </ol>
            <p class="text-uppercase fw-bold text-primary mb-0">
              PHOTOGRAPHY AND VIDEOGRAPHY
            </p>
            <ol>
              <li>
                Professional photography will take place during the event. By
                participating in the event, you consent to the organizer using
                the images for any form of publicity such as website, media and
                publication purposes.
              </li>
              <li>
                For any corrections to your registration, please contact the
                secretariat immediately at info@INCASE2023.org. The organizer
                reserves the right to modify the content (including timings) of
                the programme without prior notice.
              </li>
            </ol>

            <p class="text-uppercase fw-bold text-primary mb-0">
              PERSONAL DATA COLLECTION
            </p>
            <ol>
              <li>
                By providing your information during registration, you hereby
                agree that the INCASE2023 Organizing Committee may collect, use,
                disclose and store your data for the following purposes:
              </li>
              <li>Facilitating the management and logistics of the event</li>
              <li>
                Organizer may contact you via email or mobile for any
                notifications related to the conference
              </li>
              <li>
                Legal purposes where necessary, including investigations or
                proceedings
              </li>
              <li>
                The available information is in accordance with the Singapore
                Personal Data Protection Act – 2012 (PDPA) and the General Data
                Protection Regulation (GDPR) (EU) 2016/679
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="venue py-3">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="p-sm-4">
              <h1 class="h3 text-uppercase b-600 text-primary mb-0 headings">
                VENUE & TRAVEL
              </h1>
            </div>
            <div class="row py-2">
              <div class="col-sm-4">
                <img
                  src="@/assets/img/register/venue and travel/Travel essentials- REGISTER.png"
                  class="img-fluid"
                  alt=""
                />
                <h1 class="h3 text-uppercase b-600 text-primary mb-0 mt-2">
                  TRAVELLERS ESSENTIALS
                </h1>
                <a
                  class="mb-0 text-primary text-decoration-underline fs-5"
                  target="_blank"
                  href="https://www.visitsingapore.com/travel-guide-tips/"
                  >Welcome to SG</a
                >
                <br />
                <a
                  class="mb-0 text-primary text-decoration-underline fs-5"
                  target="_blank"
                  href="https://www.ica.gov.sg/enter-transit-depart/entering-singapore/visa_requirements"
                  >Travel Visa</a
                >
                <br />
                <a
                  class="mb-0 text-primary text-decoration-underline fs-5"
                  target="_blank"
                  href="https://www.visitsingapore.com/singapore-hotels/"
                  >Accomodation</a
                >
              </div>
              <div class="col-sm-4">
                <img
                  src="@/assets/img/register/venue and travel/See and do- REGISTER.png"
                  class="img-fluid"
                  alt=""
                />
                <h1 class="h3 text-uppercase b-600 text-primary mb-0">
                  SEE & DO
                </h1>
                <a
                  class="mb-0 text-primary text-decoration-underline fs-5"
                  target="_blank"
                  href="https://www.visitsingapore.com/see-do-singapore/"
                  >Shop, See, Do</a
                >
              </div>
              <div class="col-sm-4">
                <img
                  src="@/assets/img/register/venue and travel/Eat and dine- REGISTER.png"
                  class="img-fluid"
                  alt=""
                />
                <br />
                <a
                  class="h3 text-uppercase b-600 text-primary mb-0 text-decoration-none"
                  target="_blank"
                  href="https://www.visitsingapore.com/dining-drinks-singapore/"
                >
                  eat & drink
                </a>
                <br />
                <a
                  class="mb-0 text-primary text-decoration-underline fs-5"
                  target="_blank"
                  href="https://www.visitsingapore.com/dining-drinks-singapore/"
                  >Local specialties</a
                >
                <br /><a
                  class="mb-0 text-primary text-decoration-underline fs-5"
                  target="_blank"
                  href="https://www.visitsingapore.com/dining-drinks-singapore/"
                  >Dining Out</a
                >
                <br />
                <a
                  class="mb-0 text-primary text-decoration-underline fs-5"
                  target="_blank"
                  href="https://www.visitsingapore.com/dining-drinks-singapore/"
                  >Nightlife</a
                >
              </div>
            </div>
            <a
              href="/registration"
              class="text-center btn btn-warning fw-bold fs-3 my-5"
              >CLICK HERE TO REGISTER</a
            >
          </div>
        </div>
      </div>
    </section>

    <section class="contactus bg-light pt-4 mb-0">
      <div class="container-lg">
        <div class="d-flex justify-content-center text-center mb-4">
          <h2
            class="h2 b-600 mx-auto text-primary text-uppercase fst-italic headings"
          >
            Contact Us
          </h2>
        </div>
        <div class="row justify-content-between mb-3">
          <div class="col-sm-5">
            <p class="text-primary h5 b-600 mb-3">Drop us a message</p>
            <div class="row">
              <div class="col-6 col-sm-6">
                <div class="mb-3">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-6">
                <div class="mb-3">
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="mb-3">
                  <input
                    type="text"
                    name="subject"
                    class="form-control"
                    placeholder="Subject"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <textarea
                  name="message"
                  cols="30"
                  rows="5"
                  class="form-control"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <button
              class="btn btn-primary rounded-0 text-white b-600 text-uppercase mt-3"
            >
              Submit
            </button>
          </div>
          <div class="col-sm-5">
            <div class="mb-3">
              <p class="text-primary h5 b-600 mb-1">Conference Secretariat</p>
              <a
                href="mailto:info@INCASE2023.org"
                target="_blank"
                class="text-decoration-none text-dark"
              >
                info@INCASE2023.org
              </a>
            </div>
            <p>
              <span class="text-primary h5 b-600">Venue:</span> Holiday Inn
              Singapore Atrium
            </p>
            <div class="mapouter">
              <div class="gmap_canvas border border-white border-4 p-2">
                <iframe
                  width="100%"
                  height="300"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Holiday%20Inn%20Singapore%20Atrium,%20an%20IHG%20Hotel&t=&z=15&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <img
              src="@/assets/img/abstract/footer-bg.png"
              class="img-fluid"
              alt="bg image"
            />
          </div>
        </div>
      </div>
    </section>

    <footerComp />
  </div>
</template>

<script>
import footerComp from "@/components/layouts/footer.vue";
export default {
  name: "register",
  components: {
    footerComp,
  },
  mounted() {
    this.globalDeviceCheck();
  },
};
</script>
<style lang="scss" scoped>
.register {
  min-height: 100vh;
  .headings {
    font-family: "GothamRounded", sans-serif;
  }
}
</style>
